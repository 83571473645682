.container_modal_confirmOTP {
    margin-top: 1.5rem;
    width: 40%;
    min-width: 38rem;
    z-index: 999;
}

.success_register_teacher {
    margin-top: 150px;
}

@media screen and (max-height: 800px) {
    .container_modal_confirmOTP {
        margin-top: 1.5rem;
        min-width: 40rem;
    }
}

@media screen and (max-height: 700px) {
    .container_modal_confirmOTP {
        margin-top: 1.5rem;
        min-width: 40rem;
    }
}

@media screen and (max-width: 768px) {
    .container_modal_confirmOTP {
        min-width: 22rem;
    }
}