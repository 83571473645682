.divider_mobile_container {
  width: 100%;
  padding: 4rem 0;

  .divider_mobile {
    height: 0.6rem;
    border-radius: 1rem;
    background-color: var(--primary-green);
    width: 60%;
  }
}
