@import "/src/_styles/mixin";

.downloadPage {
  background-color: var(--white-color) !important;
  .slider {
    height: 100vh;
    min-height: 50rem;
    // max-height: ;

    .slider_image {
      width: 50%;
      max-width: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      height: 100%;
      img {
        // height: 96vh;
        max-height: 98vh;
        width: auto;
        min-height: 50rem;
      }
    }

    .slider_image_teacher {
      img {
        max-height: 90vh;
        min-height: 46rem;
      }
    }

    .slider_image_parent {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 48.5%;
      max-width: 48.5%;
      img {
        max-height: 90vh;
        min-height: 46rem;
      }
    }

    .img_side_container {
      top: 0;
      left: 0;
      img {
        width: 11vw;
        min-width: 10rem;
        max-width: 15rem;
      }
    }

    .img_side_student {
      img {
        width: 15vw;
        min-width: 14rem;
        max-width: 18rem;
      }
    }

    // List Download
    .list_download {
      width: 48%;
      max-width: 48%;
      height: 100%;
      padding: 0 1rem;
      align-items: flex-start;
      position: relative;
      top: 8vh;
      justify-content: center;

      .item_logo {
        padding-bottom: 0.8rem;
        img {
          width: clamp(9rem, 20vw, 12rem);
        }
      }

      .title_download {
        padding-bottom: 2rem;
        border-bottom: 1px solid var(--black-color);
        margin-bottom: 2rem;

        .title_detail_1 {
          color: var(--light-red-color);
          // font-size: 3rem;
          font-size: clamp(1.9rem, 4vw, 3rem);
          text-transform: uppercase;

          @include screen_mobile {
            font-size: clamp(1.3rem, 5.5vw, 1.5rem);
          }
        }

        .title_detail_1_parent {
          font-size: clamp(1.6rem, 2.5vw, 2.1875rem);
          text-transform: uppercase;

          @include screen_mobile {
            font-size: clamp(1.1rem, 5.5vw, 1.3rem);
          }
        }

        .title_detail_2 {
          // font-size: 1.7rem;
          font-size: clamp(1.1rem, 2vw, 1.7rem);
          //   margin-bottom: 2rem;
          text-transform: uppercase;

          @include screen_mobile {
            margin-bottom: 1rem;
            font-size: clamp(0.725rem, 3vw, 0.825rem);
          }
        }

        .title_detail_2_parent {
          font-size: clamp(1.6rem, 2.5vw, 2.1875rem);
          text-transform: uppercase;
          color: var(--light-red-color);

          @include screen_mobile {
            font-size: clamp(1.1rem, 5.5vw, 1.3rem);
          }
        }
      }

      .img_download_container {
        width: 100%;
        .text-download {
          line-height: normal;
          font-size: clamp(1rem, 1.7vw, 1.5rem);
        }

        .img_qr_container {
          padding-bottom: 2rem;
          width: 100%;
          img {
            width: 82%;
            max-width: 12.5rem;
            height: auto;
          }
        }

        .img_store_container {
          width: 100%;
          img {
            width: 75%;
            max-width: 9rem;
            height: auto;
          }
        }
      }
    }
  }
}
