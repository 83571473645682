@import "/src/_styles/mixin";

.box_fixed {
  position: fixed;
  bottom: 2%;
  right: 2%;
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 9998;

  @include screen_mobile {
    flex-direction: row-reverse !important;
    right: 0;
    width: 100%;
    justify-content: space-between !important;
    align-items: center;
    padding: 0 2%;
  }

  .icon_scroll_fixed {
    width: 2.6rem;
    height: 2.6rem;

    @include screen_mobile {
      margin-bottom: 0 !important;
    }
  }
}

.box-left_fixed {
  position: fixed;
  bottom: 2%;
  left: 2%;
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 9998;

  @include screen_mobile {
    left: 0;
    padding: 0 2%;
  }

  .icon_scroll_fixed {
    width: 3rem;
    height: 3rem;

    @include screen_mobile {
      margin-bottom: 0 !important;
    }
  }
}