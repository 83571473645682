@import "/src/_styles/mixin";

.header_container {
  background-color: var(--white-color);
  padding: 0.1rem 0;
  z-index: 9990;
  transition: all ease-in 0.3s;

  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  position: sticky;
  top: 0;
  // height: 75px;

  .header_content {
    margin: 0 auto;
    max-width: 88vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 9990;
    @include screen_tablet {
      max-width: 95vw;
      justify-content: space-between;
    }
    @include screen_mobile {
      max-width: 95vw;
      justify-content: space-between;
      margin-left: 3%;
      margin-right: 3%;
    }

    .img_logo {
      height: 4rem;
      width: auto;
    }
    .header_menu_icon_container {
      padding: 0.5rem;
      padding-left: 0.2rem;
      .header_menu_icon {
        width: 1.5rem;
        height: 1.5rem;
        animation: fadeIn linear 0.3s;

        @include screen_mobile {
          width: 1.3rem;
          height: 1.3rem;
        }
      }
    }

    .logo_header {
      @include screen_mobile {
        margin: 0 auto;
        padding-left: 12%;
      }
    }

    .header_list {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: center;
    }

    .header_login {
      // font-size: 0.9rem;
      // @include screen_tablet {
      //   font-size: 0.85rem !important;
      // }

      // @include screen_mobile {
      //   font-size: 0.85rem !important;
      // }

      font-size: clamp(0.75rem, 1vw, 0.9rem);
    }

    .btn_custom {
      width: clamp(5.5rem, 12vw, 7rem) !important;
      height: clamp(2rem, 4vw, 2.3rem) !important;
      // animation: scaleInOut linear 1.5s;
      // animation-iteration-count: infinite;
    }

    .name_user {
      padding-right: 1rem;
      max-width: 11vw;
      text-align: right;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;

      // font-size: 0.9rem;
      // @include screen_tablet {
      //   font-size: 0.9rem;
      // }

      font-size: clamp(0.7rem, 1.3vw, 0.9rem);
    }
    .img_avt_user {
      width: 3rem;
      min-width: 3rem;
      height: 3rem;
      @include screen_tablet {
        width: 2.6rem;
        min-width: 2.6rem;
        height: 2.6rem;
      }
      border-radius: 50%;
    }

    .img_logout_container {
      margin-left: 2.5rem;
      @include screen_tablet {
        margin-left: 1rem;
      }
      @include screen_mobile {
        margin-left: 1.3rem;
      }
      .img_logout {
        min-width: 1.6rem;
        height: 1.6rem;
        @include screen_tablet {
          min-width: 1.2rem;
          height: 1.2rem;
        }
      }
    }
  }

  .header_item {
    margin: 0 calc(2% + 0.2rem);
    position: relative;
    display: flex;
    align-items: center;
    // @include screen_tablet {
    //   font-size: 0.8rem;
    // }padding: 0.8rem 0;

    &:hover .ico_drop_tariff svg {
      transform: rotate(180deg);

      @include screen_mobile {
        transform: rotate(0);
      }
    }
    &:hover .ico_drop_tariff svg path,
    &:hover .header_item_text {
      stroke: var(--text-hover-color);
      color: var(--text-hover-color);
    }

    &::after {
      content: "";
      height: 60%;
      width: 125%;
      position: absolute;
      bottom: -30%;
      right: -5%;
      @include screen_mobile {
        display: none !important;
      }
    }

    &:hover .list_option_custom {
      display: flex !important;
      background-color: var(--white-color) !important;
    }

    .link_header_item {
      .list_option_custom {
        position: absolute;
        top: 3.3rem;
        right: 0;
        left: -75%;
        max-height: 20rem;
        min-height: 5rem;
        overflow: auto;
        margin: 0 auto;
        background-color: var(--white-color) !important;
        border-bottom-left-radius: 0.6rem;
        border-bottom-right-radius: 0.6rem;
        border-radius: 0.6rem;
        padding: 0 0.5rem;
        z-index: 99;
        width: 250%;
        min-width: 10rem;
        max-width: clamp(11.5rem, 13vw, 12.5rem);
        // border-top: 0;

        &::-webkit-scrollbar {
          width: 6px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #bbbbbb;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
          margin: 0.4rem 0;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #8b8b8b;
        }

        .option_item_custom {
          color: var(--text-color);
          padding: 0.75rem 0;
          background: white !important;
          z-index: 2;
          cursor: pointer;

          font-size: clamp(0.875rem, 1.15vw, 1.125rem);

          &:hover {
            font-family: "AvertaStdCY-Bold" !important;
            background: white !important;
          }
        }
      }
    }

    .item_bonus {
      display: inline-flex;
      background-color: var(--light-red-color);
      color: var(--white-color);
      border-radius: 0.9rem;
      padding: 0.05rem 0.4rem;
      font-size: 0.5rem;
      margin: 0 0 0.8rem 0.3rem;
      // animation: fadeIn linear 3s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
    .header_item_text_container {
      padding: 0.8rem 0;

      .ico_drop_tariff {
        margin-left: 0.33rem;

        svg {
          width: clamp(10px, 1.25vw, 13px);

          @include screen_mobile {
            width: 12px;
          }
        }
      }

      &:hover .header_item_text {
        cursor: pointer;
        color: var(--text-hover-color);
      }
    }
    .header_item_text {
      // font-size: 0.9rem;
      font-weight: 500;
      font-size: clamp(0.5rem, 1vw, 0.9rem);
    }
  }

  .modal_header_container {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    .modal_header {
      background-color: var(--white-color);
      position: absolute;
      left: 0;
      top: 4.2rem;
      width: 75vw;
      // min-height: 92vh;
      max-height: calc(100vh - 68px);
      overflow-x: auto;
      z-index: 9999;
      animation: slideIn linear 0.25s;
      display: flex;
      flex-direction: column;
      .header_item_mobile {
        width: 100%;
        border-bottom: 1px solid var(--border-color);
        padding: 0 0 0 4%;
        margin: 0;
        animation: slideIn linear 0.3s;
        &:last-child {
          border-bottom: none;
        }

        @include screen_mobile {
          &:hover .header_item_text {
            color: var(--text-hover-color);
            // border-bottom: 2px solid var(--text-hover-color);
          }
        }

        .option_item_mobile {
          font-size: clamp(0.75rem, 3.2vw, 0.875rem);
          &:hover {
            font-family: "AvertaStdCY-Bold" !important;
          }
        }
        .link_header_mobile {
          width: 100%;
          padding: 1rem 0;

          // &:hover .header_item_text {
          //   color: var(--text-hover-color);
          //   text-decoration: underline !important;
          // }
        }
        .item_bonus {
          display: inline-flex;
          background-color: var(--light-red-color);
          color: var(--white-color);
          border-radius: 0.9rem;
          padding: 0.05rem 0.4rem;
          font-size: 0.65rem;
          margin: 0 0 0.8rem 0.3rem;
          // animation: fadeIn linear 3.5s;
          animation-iteration-count: infinite;
          animation-direction: alternate;
        }
        .header_item_text {
          font-size: clamp(0.75rem, 3.2vw, 0.875rem);
          height: 100%;
        }
      }
    }
  }
}

.title_news_container {
  padding: 1rem 0 1rem 4%;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  background-color: var(--white-color);
}
