@import "/src/_styles/mixin";

.homepage {
  .slider_teacher {
    @include screen_mobile {
      background-color: #f4fdff;
      padding-bottom: 1rem;
    }
  }
  .slider {
    .slider_bg_teacher {
      width: 100%;
      position: relative;
      height: calc(100vh - 69px);
      min-height: 40rem;

      @include screen_mobile {
        flex-direction: column-reverse;
        height: calc(75vh - 69px);
        background-color: #f4fdff;
        min-height: 34rem;
      }

      .slider_3_container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        width: 50%;
        height: 100%;
        z-index: 1;

        @include screen_mobile {
          // align-items: flex-end;
          // justify-content: center;
          width: 100% !important;
          object-fit: contain;
        }

        .title_container_teacher {
          width: 90%;
          margin: 0 auto;
          display: flex;
          justify-content: flex-start;
          position: relative;
          top: 7.8vh;

          @include screen_mobile {
            width: 100% !important;
            min-height: 38%;
            padding-top: 2vh;
            top: 0;
            align-items: flex-start;
            padding-left: 4%;
          }

          .title_detail_1_teacher {
            font-size: 2.6rem;
            background-color: var(--light-red-color);
            padding: 0.5rem 1rem;
            text-align: center;
            color: var(--white-color);
            font-size: clamp(2.2rem, 4vw, 2.6rem);
            min-width: 23.5rem;

            @include screen_mobile {
              width: 85%;
              font-size: clamp(1.7rem, 8vw, 2rem);
              min-width: 88%;
              line-height: 39px;
              background-color: transparent;
              color: var(--light-red-color);
              text-align: start;
              padding: 0;
            }
          }

          .title_detail_2_teacher {
            line-height: 49px;
            @include screen_mobile {
              font-size: clamp(1.05rem, 6vw, 1.25rem);
              line-height: 27px;
              text-align: start;
            }
          }

          .title_detail_3_teacher {
            line-height: 49px;
            @include screen_mobile {
              font-size: clamp(1.6rem, 9vw, 1.875rem);
              // line-height: 37px;
              text-align: start;
            }
          }
        }

        .slider_3 {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          width: 100%;
          overflow: hidden;
          min-width: 30rem;
          z-index: 3;
          @include screen_mobile {
            justify-content: flex-start;
            width: 80%;
            min-width: 7rem;
          }

          .slider_3_img {
            object-fit: contain;
            max-height: 86%;
            width: auto;
            z-index: 3;

            @include screen_mobile {
              max-height: 100%;
              // width: auto;
            }
          }
        }
      }

      .video_slider_container {
        width: 47%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 0;

        @include screen_mobile {
          position: absolute;
          right: 4%;
          bottom: 3%;
          width: 100%;
          justify-content: flex-end;
          height: auto;
          // max-height: 80%;
        }
        .bg_circle_img {
          top: 0;
          bottom: 0;
          margin: auto 0;
          vertical-align: middle;
          max-height: 58%;
        }
        .video_slider {
          max-height: 85.9%;
          width: auto;
          z-index: 1;

          object-fit: fill;
          border: 0.6rem solid var(--white-color);
          border-radius: 2rem;
          right: 1rem;
          bottom: 0.5rem;
          @include screen_mobile {
            display: flex;
            align-items: center;
            max-height: calc((56vh - 70px));
            height: 85vw;
            min-height: 21rem;
            border-radius: 1rem;
            border: 0.4rem solid var(--white-color);
          }
        }
      }

      .icon_half_slider_container {
        position: absolute;
        bottom: 10vh;
        right: 0;
        img {
          width: 4vw;
          min-width: 3.5rem;
        }
      }

      // Mobile
      .img_slider_mobile {
        bottom: 0;
        z-index: 0;
      }
    }
  }
  .content_banner_container {
    background-color: var(--white-color) !important;
    margin-top: 0rem;

    .content_bg_banner {
      margin: 2rem 0 !important;
    }
  }
  .content {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 5rem;

    .content_bg_benefit_teacher {
      // background-color: var(--bg-light-blue-color) !important;
      background-color: var(--white-color);

      @include screen_mobile {
        background-color: var(--white-color);
      }

      .detail_assistant_container {
        padding: 2.5rem 0 3rem;

        .title_detail_assistant {
          font-size: clamp(2.5rem, 3vw, 2.8125rem);
          @include screen_mobile {
            font-size: clamp(1.25rem, 5vw, 1.375rem);
            width: 45%;
            min-width: 14rem;
          }

          .img_side_left {
            top: 0;
            left: 0;

            img {
              width: 8vw;
              min-width: 5rem;
            }
          }
          .img_side_right {
            top: 0;
            right: 0;
            img {
              width: 8vw;
              min-width: 5rem;
              transform: scaleX(-1);
            }
          }
        }

        .img_detail_assistant {
          width: 42%;
          max-width: 42%;

          @include screen_mobile {
            width: 98%;
            max-width: 98%;
            padding-bottom: 2rem;
          }
        }

        .detail_assistant_content {
          padding-top: 3rem;
          border-bottom: 1px solid var(--black-color);
          display: flex;

          @include screen_mobile {
            padding-top: 1rem;
            border-bottom: none;
          }

          .list_detail_assistant {
            width: 54%;
            max-width: 54%;
            display: flex;
            flex-direction: column;
            padding-left: 4%;

            @include screen_mobile {
              width: 82%;
              max-width: 82%;
              padding-left: 0%;
            }

            .name_detail {
              font-size: clamp(1rem, 1.25vw, 1.25rem) !important;
            }

            .icon_tick_success {
              width: clamp(1.2rem, 1.25vw, 1.3rem) !important;

              @include screen_mobile {
                width: 1rem !important;
              }
            }
          }
        }
      }
    }

    .img_benefits_teacher {
      width: 88%;
      max-width: 95rem;
      padding-top: 5rem;

      @include screen_mobile {
        max-width: 28rem;
        padding: 1.5rem 0 2.5rem;
        min-width: 18rem;
      }
    }

    .list_content_teacher {
      width: clamp(78%, 20vw, 72%);
      padding-bottom: 3rem;
      min-width: 46rem;
      max-width: 100rem;
      z-index: 1;
      @include screen_mobile {
        width: 96%;
        min-width: 96%;
        padding-bottom: 1rem;
      }

      .list_detail_teacher {
        padding: 2.6rem 0rem 2.2rem;

        @include screen_mobile {
          flex-direction: column !important;
          width: 80%;
          max-width: 80%;
          justify-content: center;
          min-width: 20rem;
          padding: 1.5rem 0 1rem;
        }

        .detail_teacher_column {
          @include screen_mobile {
            padding: 0 !important;
            padding-top: 1.8rem !important;
          }
          .text_title_benefit {
            font-size: clamp(1.35rem, 2vw, 1.625rem) !important;

            @include screen_mobile {
              font-size: clamp(1.05rem, 3.75vw, 1.125rem) !important;
            }
          }

          .name_detail {
            font-size: clamp(1.125rem, 1.6vw, 1.375rem) !important;
            @include screen_mobile {
              font-size: clamp(0.9rem, 2.75vw, 1rem) !important;
            }
          }
        }
        .img_item_detail {
          min-width: 38%;
          @include screen_mobile {
            max-width: 100%;
          }
        }
      }
    }
    .detail_item {
      @include screen_mobile {
        margin-bottom: 0.75rem !important;
      }
    }

    .trial_teacher_container {
      background-color: var(--white-color);
      padding: 0.5rem 0 3.5rem;
      position: relative;

      @include screen_mobile {
        padding-bottom: 2rem;
      }
      .img_box_trial_teacher {
        width: 50%;
        min-width: 20rem;

        @include screen_mobile {
          width: 90%;
          min-width: 15rem;
          margin-bottom: 1rem;
        }
      }

      .btn_custom {
        height: clamp(3.5rem, 5vw, 4.3rem);
        @include screen_mobile {
          height: 2.875rem;
          width: clamp(14rem, 40vw, 16rem) !important;
        }
        .text_btn_test {
          font-size: clamp(1.4rem, 2vw, 1.75rem);

          @include screen_mobile {
            font-size: clamp(1rem, 2vw, 1.15rem);
          }
        }
      }

      .icon_left_trial_container {
        position: absolute;
        bottom: 50%;
        left: 0;
        img {
          width: 14vw;
          min-width: 10rem;
        }
      }
      .icon_right_trial_container {
        position: absolute;
        bottom: 20%;
        right: 0;
        img {
          width: 14vw;
          min-width: 10rem;
        }
      }
    }

    .list_icon_bg_teacher {
      .img_left_top_teacher {
        left: 0;
        top: 2%;
        // z-index: 0;
        img {
          width: 11vw;
          min-width: 8rem;
          transform: scaleY(-1);
          // transform: scaleX(-1);
        }
      }
      .img_left_center {
        left: 0;
        top: 30%;
        z-index: 0;
        img {
          width: 8vw;
          min-width: 6rem;
        }
      }
      .img_right_top {
        right: 0;
        top: 3%;
        z-index: 0;
        img {
          width: 9.5vw;
          min-width: 8rem;
        }
      }
      .img_right_bottom {
        z-index: 0;
        right: 0;
        bottom: 10%;
        img {
          width: 9vw;
          min-width: 6rem;
        }
      }
    }
  }
}
