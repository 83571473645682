@import "/src/_styles/mixin";

.activationNewsPage {
  .content {
    padding: 1.25rem 0 1.75rem;
    width: 100%;
    @include screen_mobile {
      margin-bottom: 2.5rem;
      padding-top: 1.75rem;
    }
    .list_activation {
      width: 100%;
      .activation_item {
        margin-bottom: 2.5rem;

        @include screen_mobile {
          margin-bottom: 1.5rem;
        }
        .activation_title {
          font-size: clamp(1.75rem, 2vw, 2rem);
          padding: 0.5rem 0 0.25rem;

          @include screen_mobile {
            font-size: clamp(0.75rem, 4vw, 1rem);
          }
        }
      }

      .activation_device_title {
        background-color: var(--primary-green);
        padding: 0.25rem 3rem;
        height: clamp(3.5rem, 4vw, 4rem);
        border-radius: 4rem;
        margin-bottom: 1rem;
        margin-top: 1.5rem;

        @include screen_mobile {
          padding: 0.25rem 1rem;
          height: clamp(2.5rem, 4vw, 2.75rem);
          margin: 0.5rem 0 0.75rem;
        }
        img {
          width: clamp(1.8rem, 2.2vw, 2rem);
          height: auto;

          @include screen_mobile {
            width: clamp(1.125rem, 4.5vw, 1.5rem);
          }
        }
        .activation_device_text {
          font-size: clamp(1.25rem, 1.5vw, 1.5rem);
          margin-left: 1rem;

          @include screen_mobile {
            font-size: clamp(0.875rem, 4.5vw, 1rem);
          }
        }
      }
      .activation_device {
        width: 84%;

        @include screen_mobile {
          width: 88%;
          padding-bottom: 0.25rem;
        }
      }

      .activation-computer__register {
        background-color: #f4fdff;
        width: 100%;
        padding-left: 8%;
        padding-right: 8%;

        @include screen_mobile {
          padding-left: 5%;
          padding-right: 5%;
        }
      }

      .activation_content {
        .activation_content_title {
          padding-top: 3rem;
          padding-bottom: 0.5rem;

          @include screen_mobile {
            padding-top: 0.75rem;
            padding-bottom: 0.25rem;
          }
          img {
            width: clamp(1rem, 3vw, 1.3rem);
            height: auto;
          }

          h2 {
            font-size: clamp(1.125rem, 1.4vw, 1.375rem);
            margin-left: 0.5rem;
          }
        }
      }

      .activation_list_steps {
        padding: 1rem 2.5% 2rem;

        @include screen_mobile {
          flex-direction: column;
          flex-wrap: nowrap;
          padding: 0.5rem 0 0.75rem;
          align-items: center;
        }
        .activation_step {
          // flex: 1 0 auto;
          justify-content: space-between !important;

          @include screen_mobile {
            width: 96%;
            padding-top: 0;
            padding-bottom: 1rem;
          }
        }
      }

      .step_text {
        font-size: clamp(1.125rem, 1.3vw, 1.25rem);

        @include screen_mobile {
          font-size: clamp(0.875rem, 4.5vw, 1rem);
        }
      }

      .step-title__social {
        padding-left: 8%;

        @include screen_mobile {
          padding-left: 2%;
        }
      }

      .step-image__container {
        @include screen_mobile {
          width: 100%;
        }
      }
      .step-image__mobile {
        width: calc(16vw + 1rem);
        &:not(:last-child) {
          margin-right: clamp(0.75rem, 2vw, calc(2.5rem - 2%));
        }
        @include screen_mobile {
          width: 78%;
          margin-right: 0 !important;
        }
        img {
          width: 100%;
          min-width: 100%;
        }
      }

      .step-image__pc {
        img {
          width: 84%;
          @include screen_mobile {
            width: 96%;
          }
        }

        .img-step__side--big {
          width: 63.5%;

          @include screen_mobile {
            width: 73%;
          }
        }

        .img-step__side--small {
          width: 17.5%;
          margin-left: 3%;

          @include screen_mobile {
            width: 20%;
            margin-left: 3%;
          }
        }
      }

      .step-arrow__big {
        width: 20%;
        margin-left: -0.9%;

        img {
          height: 0.7rem;
          object-position: right;
        }
      }

      .step-arrow__small {
        width: 7.5%;
        img {
          height: 0.6rem;
          object-position: right;
        }
      }

      .step-arrow__abs {
        top: 0;
        bottom: 0;
        left: -9.5%;
      }

      .step-arrow__small--package {
        width: 6.5vw;
        max-width: 12rem;
        left: calc(-6.5vw - 1rem);
      }
    }

    .activation-header {
      @include screen_mobile {
        border: 1px solid var(--primary-green);
        padding: 0.45rem 1rem 0.45rem;
        border-radius: 0.4375rem;
        cursor: pointer;
        width: 92%;
      }
      .img_dropdown {
        @include screen_mobile {
          min-width: clamp(1.5rem, 2.75vw, 2rem);
          min-height: clamp(1.5rem, 2.75vw, 2rem);
          width: clamp(1.5rem, 2.75vw, 2rem);
          height: clamp(1.5rem, 2.75vw, 2rem);
        }
      }
    }
  }
}
