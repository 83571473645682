@import "/src/_styles/mixin";

.mockTestNews {
  position: relative;
  background-color: var(--white-color);
  .content {
    position: relative;
    background-color: var(--white-color);
    // min-height: 100rem;
    max-width: 90vw;
    margin: 0 auto;

    @include screen_mobile {
      max-width: 95vw;
    }

    .img_banner_mockTest {
      // height: 20rem;
      object-fit: contain;
      width: 100%;
    }

    .content_1 {
      padding: 3rem 3%;

      .input_search_container {
        flex: 1;
        height: 90%;
        .input_text_base_container .input_text_base {
          height: 3.5rem;
        }

        .input_text_base {
          margin-bottom: 0 !important;
        }

        .input_text {
          font-size: 1.1rem;
        }
      }

      .text_invalid_search {
        margin: auto;
        height: 100%;
        vertical-align: middle;
      }

      .table_list_mocktest {
        margin: 4rem 0;
        display: flex;
        flex-direction: column;
        max-height: calc(33rem + 1.2vw);
        min-height: 6rem;
        overflow-y: scroll;
        scroll-behavior: smooth;
        position: relative;
        // padding-right: 1rem;

        @include screen_mobile {
          border: none;
        }

        .table_divider_bottom {
          position: sticky;
          bottom: 0;
          width: 100%;
          height: 1.5px;
          background-color: #000;
        }
        .table_list_mocktest_container {
          padding-right: 1rem;
        }

        thead {
          vertical-align: middle;
          position: sticky;
          top: -0px;
          // border: 1px solid var(--text-color);
          z-index: 90;
        }

        th {
          vertical-align: middle;
          background-color: #fff;
          border: 1px solid var(--black-color);
          padding: 1rem 2%;
        }

        td {
          border-right: 1px solid var(--black-color);
          padding: 1.5rem 2%;
          vertical-align: middle;
        }

        tr:nth-child(odd) {
          background-color: #fafafa;
        }

        table {
          border-collapse: separate;
          border: solid var(--black-color) 1px;
          border-top: none;
        }

        td,
        th {
          border-collapse: separate;
          border: solid var(--black-color) 1px;
          // border-top: solid var(--black-color) 1px;
        }

        th {
          border-top: 1.5px solid var(--black-color);
          border-bottom: 1.5px solid var(--black-color);
        }

        tr:first-child {
          td {
            border-top: none;
          }
        }
      }
    }

    .content_1_mobile {
      padding: 2rem 3%;
      .input_search_container_mobile {
        flex: 1;
        height: 90%;
        // overflow: hidden;
        .input_text_base_container .input_text_base {
          height: 3.2rem;
        }

        .input_text_base {
          padding: 0 0.5rem !important;
          @include screen_mobile {
            margin-bottom: 0 !important;
          }
        }

        .img_label_input {
          width: 1.2rem !important;
          height: auto !important;
        }
      }

      .icon_filter_container {
        padding-left: 3%;
        padding-top: 0.3rem;
        // height: 90%;
        .icon_filter_mocktest {
          width: 3rem;
          height: auto;
        }
      }

      .box_mocktests_scroll {
        display: flex;
        flex-direction: column;
        max-height: 40rem;
        min-height: 6rem;
        overflow-y: scroll;
        scroll-behavior: smooth;
        position: relative;
        align-items: center;
        margin-top: 2.5rem;
        .list_box_mocktests {
          width: 85vw;
          padding: 0 3%;

          .box_item_mocktest {
            width: 100%;
            border-radius: 0.8rem;
            padding: 1.3rem 7%;
            margin-bottom: 1.5rem;
            background-color: var(--white-color);
            border: 1px solid var(--text-color);

            .icon_box_container {
              padding-right: 0.5rem;
              width: 1.5rem;
              .icon_box_mocktest {
                width: 2rem;
                height: auto;
                min-width: 1.05rem;
              }
              .icon_participant {
                width: 1.2rem;
                min-width: 1rem;
              }
            }

            .box_item_info_left {
              max-width: 55%;
            }

            .img_achievement {
              width: clamp(6.5rem, 25vw, 10rem);
              height: auto;
              min-width: 7rem;
            }

            .point_achievement_text {
              font-size: clamp(0.875rem, 3vw, 1rem);
              position: absolute;
              top: 25%;
              right: 25%;
              color: var(--white-color);
            }
          }
        }
      }
    }
  }
}
