@import "/src/_styles/mixin";

.footer_wrapper {
  padding: 3rem 0rem 2rem;
  background-color: var(--light-gray-color);

  @include screen_mobile {
    background-color: var(--white-color);
    padding-top: 0;
  }
  .footer_container {
    display: flex;
    padding-bottom: 6.5rem;
    width: 100%;
    justify-content: space-between;
    padding-left: clamp(2%, 4.2vw, 6%);
    padding-right: clamp(2%, 4.2vw, 6%);

    @include screen_mobile {
      flex-direction: column-reverse;
      padding-bottom: 2.5rem;
      padding-left: 0;
      padding-right: 0;
    }

    .footer_left_container {
      max-width: 30%;
      width: 30%;
    }

    .footer_left_container,
    .footer_right_container {
      @include screen_mobile {
        width: 100% !important;
        max-width: 100% !important;
        margin: 0;
      }
    }

    .footer_right_container {
      width: 70%;
      max-width: 100rem;
      margin: 0 auto;
      // margin-left: clamp(1%, 11vw, 15%);
      @include screen_mobile {
        flex-direction: column;
        margin-left: 0;
      }

      .footer_right_item {
        // margin-left: 2%;
        @include screen_mobile {
          width: 100% !important;
          max-width: 100% !important;
          margin: 0;
        }
      }
    }

    .title_footer_container {
      @include screen_mobile {
        border-top: 1px solid #e6e6e6;
        margin: 0;
        padding: 0.7rem 0;
        cursor: pointer;
        padding-left: 4%;
        padding-right: 3%;
      }
    }
    .title_footer {
      font-size: clamp(1rem, 1.6vw, 1.4rem);

      @include screen_mobile {
        font-size: 0.95rem;
      }
    }

    .footer_intro_modal {
      @include screen_mobile {
        animation: slideTopIn linear 0.3s;
      }
    }

    .margin_horizontal {
      margin: 0 1.5rem;
    }

    .footer_contact_box {
      // border: 1px solid var(--border-color);
      padding: 0.23rem 0;
      border-radius: 0.4rem;
      max-width: 19rem;
      width: 100%;

      @include screen_mobile {
        max-width: 96%;
        width: 96%;
        // max-width: 60%;
        // justify-content: center;
      }
      .icon_contact {
        width: 1.5rem;
        height: auto;

        @include screen_mobile {
          width: 1.8rem;
        }
      }
    }

    .footer_email_contact {
      @include screen_mobile {
        width: auto;
        max-width: auto;
      }
    }

    .footer_social_container {
      align-items: flex-end;
      justify-content: space-between;
      padding: 1.2rem 0;
      // padding-right: clamp(0.5rem, 5vw, 1rem);
      // padding-left: clamp(2rem, 4.2vw, 5.5rem);
      margin-top: 2rem;
      // border: 1px solid var(--border-color);
      border-radius: 0.5rem;
      @include screen_mobile {
        // flex-direction: row-reverse;
        justify-content: flex-end !important;
        align-items: flex-start;
        margin: 0 !important;
        padding: 0.5rem 0 2rem;
        border: none;
        padding-top: 1.75rem;
      }

      .item_infor_download {
        padding-right: clamp(2.3rem, 5.5vw, 7rem);

        &:last-child {
          padding-right: 0.5rem;
          margin-right: clamp(-3rem, 4.5vw, -6rem);

          @include screen_tablet {
            margin-right: clamp(-0.5rem, 20vw, -6rem);
          }

          @include screen_mobile {
            margin-right: 0;
            padding-right: 0;
          }
        }
        @include screen_mobile {
          flex-direction: column;
          align-items: center;
          width: 100%;
          // justify-content: space-between !important;
          padding-bottom: 1.3rem;
          padding-right: 0;
        }

        .item_infor_title {
          padding-bottom: 1rem;
          align-items: flex-start;

          @include screen_mobile {
            padding-bottom: 0;
            width: 90%;
            max-width: 90%;
            padding-right: 0.8rem;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.5rem;
          }
          span {
            font-size: clamp(0.75rem, 1.1vw, 0.875rem);
            @include screen_mobile {
              font-size: clamp(0.7rem, 4vw, 0.875rem);
            }
          }

          .img_tick_title {
            width: 1.2rem;
            margin-right: 0.3rem;
            margin-top: 0.05rem;

            @include screen_mobile {
              width: clamp(0.75rem, 4vw, 0.9375rem);
            }
          }
        }

        .img_download_container {
          // padding: 0 1rem 0.8rem;
          justify-content: flex-start;
          @include screen_mobile {
            // width: 60%;
            // max-width: 60%;
            // padding: 0 0 0.8rem 0;
            // max-height: 90%;
            margin: 0 0.5rem;
            // width: 90%;
            // max-width: 90%;
            justify-content: center;
          }
        }
        .icon_qr {
          // margin-right: 13%;
          width: 30%;
          height: 100%;
          min-height: 100%;
          max-width: 10rem;
          min-width: 30%;

          @include screen_mobile {
            margin-right: 4%;
            width: 11.5%;
            max-width: 3.3rem;
            min-width: 11.5%;
          }

          img {
            object-fit: contain;
            width: 100%;
            max-width: 100%;

            @include screen_mobile {
              max-width: 3rem;
            }
          }
        }

        .footer_app_download_container {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          // width: 65%;
          max-width: 65%;
          @include screen_mobile {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            max-width: 100%;
          }
          .img_app_download_container {
            max-width: 96%;
            @include screen_mobile {
              width: 100%;
              max-width: 100%;
            }
          }
          .img_app_download {
            // height: 1.6rem;
            // min-height: 1.6rem;
            width: 65%;
            max-width: 13rem;

            @include screen_mobile {
              margin: 0 !important;
              width: 90%;
              max-height: 90%;
              max-width: 10rem;
            }
          }
        }
      }
    }
    .footer_email_social {
      @include screen_mobile {
        flex-wrap: wrap;
        margin-bottom: 0.5rem !important;
      }
    }
    .icon_social_container {
      padding-bottom: 1.5rem;
      @include screen_mobile {
        min-width: 28%;
        justify-content: center;
        // max-width: 100%;
        // width: 100%;
        // padding-right: 3%;
        // min-width: 3rem;
        margin-bottom: 0.2rem;
        padding-bottom: 0;
      }
      .icon_social {
        cursor: pointer;
        width: 4.4rem;
        height: auto;
        -webkit-transition: all ease-out 0.3s;
        transition: all ease-out 0.3s;
        -webkit-transform: scale(1);
        transform: scale(1);
        margin-right: 0.5rem;

        @include screen_mobile {
          width: 3.2rem;
          min-width: 2rem;
          margin-right: 0;
        }

        &:hover {
          -webkit-transition: all ease-out 0.3s;
          transition: all ease-out 0.3s;
          -webkit-transform: scale(1.3);
          transform: scale(1.3);
        }
      }
    }

    .list_option_right {
      @include screen_mobile {
        min-width: 50%;
        margin-bottom: 1rem;
      }
    }

    .footer_1_tail {
      padding-top: 1.1rem;
      border-top: 1px solid var(--border-color);

      @include screen_mobile {
        border-bottom: 1px solid var(--border-color);
        padding-bottom: 1.5rem;
      }
    }

    .text_title_contact {
      font-size: clamp(1rem, 1.6vw, 1.4rem);
    }

    .text_phone_number {
      font-size: clamp(0.95rem, 1.3vw, 1.125rem) !important;
    }

    .text_email {
      font-size: clamp(0.95rem, 1.3vw, 1.125rem) !important;
    }

    .footer_detail_item {
      font-size: clamp(0.8rem, 0.8vw, 0.875rem);
    }
  }
  .img_bct {
    @include screen_mobile {
      width: clamp(9rem, 45vw, 11rem);
      height: auto;
      padding-bottom: 2rem;
    }
  }

  .text_license {
    @include screen_mobile {
      padding: 0 1rem;
    }
  }
}
