.register_step {
  .title_register {
    margin-bottom: 18px;

    @media screen and (max-width: 768px) {
      margin-bottom: 14px;
    }
  }

  .step_register_name {
    font-size: 24px;
    line-height: 28px;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  .login_text {

    p,
    a {
      font-size: 18px;
    }
  }

  .field_item {
    margin-bottom: 34px;

    @media screen and (max-width: 768px) {
      margin-bottom: 24px;
    }

    .field_birthday {
      margin-bottom: 0px;
    }
  }

  .field_province {
    .drop_down {
      @media screen and (max-height: 800px) {
        max-height: 260px;
      }
    }
  }

  .field_district {
    .drop_down {
      @media screen and (max-height: 800px) {
        max-height: 200px;
      }
    }
  }

  .field_school {
    .drop_down {
      max-height: 150px;
    }
  }

  .input_select_main_wrapper {
    margin-bottom: 0;
  }

  .search_option_item {
    .title {
      font-size: 16px;
    }
  }
}

.register_create_account {
  .step_register_name {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 36px;
  }
}