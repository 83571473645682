.register_step_success {
  .title_register {
    margin-bottom: 36px;
  }
  .success_icon {
    text-align: center;
    margin-bottom: 44px;
  }
  .success_message {
    text-align: center;
    p {
      font-size: 18px;
    }
  }
  .back_login {
    text-align: center;
    margin-top: 44px;
    button {
      width: 210px;
      height: 50px;
      line-height: unset;
      border-radius: 25px;
      font-size: 18px;
    }
  }
}
