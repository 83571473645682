.select_container {
  position: relative;
  min-width: 15%;
  max-width: 18%;
  width: 18%;
  margin-right: 2%;
  cursor: pointer;
  position: relative;
  .select_title {
    font-size: clamp(0.9rem, 1.5vw, 1.1rem);
    .icon_drop_custom {
      margin-left: 0.5rem;
    }
  }

  .list_option_custom {
    position: absolute;
    top: 2rem;
    right: 0;
    left: 0;
    max-height: 20rem;
    min-height: 10rem;
    overflow: auto;
    background-color: var(--white-color);
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    border-radius: 0.6rem;
    z-index: 99;
    border: 1px solid var(--text-color);
    // border-top: 0;

    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #bbbbbb;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      margin: 0.4rem 0;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #8b8b8b;
    }

    .option_item_custom {
      color: var(--text-color);
      padding: 1rem 0;

      font-size: clamp(0.9rem, 1.5vw, 1rem);
    }
  }
}
