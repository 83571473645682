@import "/src/_styles/mixin";

.homepage {
  .slider {
    position: relative;
    .slider_bg_parent {
      width: 100%;
      position: relative;
      height: calc(100vh - 69px);
      background-color: #f4fdff !important;
      overflow: hidden;
      min-height: 43rem;
      // background-color: var(--bg-light-blue-color);
      @include screen_mobile {
        flex-direction: column;
        height: calc(75vh - 69px);
        min-height: 34rem;
      }
      .slider_4_mobile {
        width: 50%;
        height: 100%;
        @include screen_mobile {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-direction: column;
          width: 100% !important;
          object-fit: contain;
          // max-height: 62%;
        }
        .slider_4 {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          width: 100%;
          min-width: 100%;
          overflow: hidden;
          @include screen_mobile {
            justify-content: flex-start !important;
            width: 75%;
            height: 100%;
            min-width: 100%;
          }
          z-index: 1;
          .slider_4_img {
            object-fit: contain;
            max-height: 92%;
            width: auto;
            @include screen_mobile {
              max-height: 80%;
              // width: auto;
              max-width: 72%;
            }
          }
        }
      }

      .slider_4_full {
        width: 100%;
        background-color: #f4fdff;
        // max-height: 90%;
        // max-height: 100vh;
        height: 100%;
        align-items: flex-end;
        .slider_4_img {
          object-fit: contain;
          // max-height: 90%;
          // width: auto;
          // max-height: 130vh;
          margin: 0 auto;
          // min-height: 30rem;
          width: 100%;
          @include screen_mobile {
            max-height: 100%;
            width: 100% !important;
            object-fit: cover;
          }
        }
      }
      .video_slider_container {
        width: 100%;
        height: 100%;
        max-height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        top: 0;
        right: 6.5%;
        z-index: 0;
        @include screen_mobile {
          position: absolute;
          right: 2.2%;
          bottom: 3%;
          width: 100%;
          justify-content: flex-end;
          align-items: flex-end;
          height: auto;
          // max-height: 80%;
        }
        .video_slider_wrapper {
          right: 0;
          bottom: 0%;
          max-width: 20rem;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          z-index: 0;

          @include screen_mobile {
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            position: relative;
          }
        }
        .video_slider {
          // max-height: 75vh;
          // height: 100%;
          // width: clamp(68%, 20vw, 95%);
          position: absolute;
          // top: -0.6rem;
          // left: -0.6rem;
          z-index: 2;
          object-fit: fill;
          // @include screen_mobile {
          //   max-height: calc(45vh - 70px);
          //   height: 75vw;
          //   min-height: 16rem;

          //   right: 0.8rem;
          //   bottom: 1.5rem;
          //   border-radius: 1rem;
          //   border: 0.45rem solid var(--white-color);
          // }
        }

        .img_slider_side {
          position: absolute;
          bottom: 5%;
          right: -10%;
          max-height: 15%;
          max-width: 30%;

          @include screen_mobile {
            right: -5%;
            bottom: 5%;
            max-height: 14%;
            // max-width: auto;
          }
        }
      }

      .icon_right_slider_container {
        position: absolute;
        top: -2rem;
        right: 0;
        z-index: 0;
        img {
          width: 6vw;
          min-width: 5.5rem;

          @include screen_mobile {
            width: 5vw;
            min-width: 4.5rem;
          }
        }
      }

      .title_container_parent {
        max-width: 50%;
        height: 100%;
        position: absolute;
        top: 7vh;
        margin-left: 9vw;
        z-index: 2;

        @include screen_mobile {
          max-width: 100% !important;
          margin: 0;
          margin-left: 8vw;
          padding-top: 3vh;
          position: relative;
          top: 0;
          z-index: 2;
          height: auto;
        }
        .title_detail_1_parent {
          line-height: 44.5px;
          font-size: clamp(2rem, 4vw, 2.2rem);
          // font-size: 2.2rem;
          @include screen_mobile {
            line-height: 29.6px;
            font-size: clamp(1.25rem, 6vw, 1.5rem);
          }
        }
        .title_detail_2_parent {
          line-height: 74px;
          font-size: clamp(3rem, 4vw, 3.7rem);
          @include screen_mobile {
            line-height: 49.5px;
            font-size: clamp(2rem, 9vw, 2.5rem);
          }
        }

        .title_detail_3_parent {
          width: 75%;
          // font-size: 2.25rem;
          font-size: clamp(1.925rem, 3vw, 2.25rem);
          line-height: 44.5px;
          @include screen_mobile {
            width: 85%;
            line-height: 29.66px;
            font-size: clamp(1.25rem, 6vw, 1.5rem);
          }
        }
      }

      .img_slider_mobile {
        bottom: 0;
        z-index: 0;
      }
    }
  }

  .content {
    position: relative;
    padding-top: 1rem;
    padding-bottom: 5rem;

    @include screen_mobile {
      padding-bottom: 5rem;
    }

    .content_bg_benefit {
      .list_content_parent {
        width: 42.1%;
        // padding-bottom: 3rem;
        min-width: 43rem;
        max-width: 55rem;
        z-index: 1;

        @include screen_mobile {
          width: 88% !important;
          min-width: 88% !important;
        }

        .list_detail_parent {
          padding: 3rem 0.8rem;

          @include screen_mobile {
            padding: 2.5rem 0;
            padding-bottom: 0;
            border: none !important;
          }

          .img_item_detail {
            @include screen_mobile {
              width: 3.4rem !important;
              height: auto;
            }
          }

          .detail_item_parent {
            @include screen_mobile {
              padding-bottom: 2rem;
            }
            .name_detail {
              @include screen_mobile {
                font-size: 1rem !important;
              }
            }
          }
        }

        .icon_tick_success {
          // margin-top: 2% !important;

          @include screen_mobile {
            margin-top: 0.3rem !important;
          }
        }
      }
      .list_icon_bg {
        .img_left_top {
          left: 0;
          top: 18%;
          z-index: 0;
          img {
            width: 11vw;
            min-width: 8rem;
            transform: scaleY(-1);
            transform: scaleX(-1);
          }
        }
        .img_left_center {
          left: 0;
          top: 45%;
          z-index: 0;
          img {
            width: 13vw;
            min-width: 9rem;
          }
        }
        .img_left_bottom {
          left: 5%;
          bottom: 15%;
          z-index: 0;
          img {
            width: 8.5vw;
            min-width: 7rem;
          }
        }
        .img_right_bonus {
          right: 5%;
          top: 5%;
          z-index: 0;
          img {
            width: 5vw;
            min-width: 4.5rem;
          }
        }
        .img_right_top {
          right: 0;
          top: 28%;
          z-index: 0;
          img {
            width: 9.5vw;
            min-width: 8rem;
          }
        }
        .img_right_bottom {
          z-index: 0;
          right: 0;
          bottom: 30%;
          img {
            width: 11vw;
            min-width: 8rem;
          }
        }
      }

      .list_icon_bg_mobile {
        .img_left_center {
          left: 0;
          bottom: 34%;
          z-index: 0;
          img {
            width: 5vw;
            min-width: 3.8rem;
          }
        }
        .img_right_top {
          right: 0;
          top: 10%;
          z-index: 0;
          img {
            width: 4.5vw;
            min-width: 3rem;
          }
        }
        .img_right_bottom {
          right: 0;
          bottom: 20%;
          z-index: 0;
          img {
            width: 4vw;
            min-width: 2rem;
            transform: scaleX(-1);
          }
        }
      }
    }

    .trial_parent_container {
      padding: 0.5rem 0 3.5rem;

      @include screen_mobile {
        padding-top: 2.5rem;
        padding-bottom: 0rem;
      }
      .btn_custom {
        height: clamp(3.5rem, 5vw, 4.3rem);
        @include screen_mobile {
          height: 2.875rem;
          width: clamp(14rem, 40vw, 16rem) !important;
        }
        .text_btn_test {
          font-size: clamp(1.4rem, 2vw, 1.75rem);

          @include screen_mobile {
            font-size: clamp(1rem, 2vw, 1.15rem);
          }
        }
      }
    }

    .content_banner_top_container {
      width: 100%;
      background-color: var(--white-color);
      padding-bottom: 4rem;
      .content_bg_top_banner {
        margin: 2rem 0 2rem 0;
        width: 100%;
        height: 100%;

        .img_banner_top {
          max-height: 85%;
          max-width: 50%;
          object-fit: cover;
          // margin-left: 1rem;

          @include screen_mobile {
            max-width: 96% !important;
          }
        }
      }
      .icon_left_parent_container {
        position: absolute;
        bottom: 3rem;
        left: 0;
        img {
          width: 14vw;
          min-width: 9rem;
        }
      }
      .icon_right_parent_container {
        position: absolute;
        // bottom: 50%;
        top: 0;
        right: 0;
        img {
          width: 14vw;
          min-width: 9rem;
        }
      }
    }
  }
}
