@import "/src/_styles/mixin";

.slogan_container {
  width: 100%;
  background-color: var(--white-color);

  @include screen_mobile {
    padding: 0 3%;
  }
  .slogan_content {
    width: 100%;
    position: relative;
    margin-bottom: 2.5rem;

    .slogan_img {
      width: 100%;
      object-fit: contain;
    }

    .btn_buy_container {
      position: absolute;
      right: 2%;
      bottom: 10%;
      max-width: 50%;

      @include screen_mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        width: 100%;
        bottom: 2%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }

      .btn_custom {
        @include screen_mobile {
          width: 12rem !important;
          // height: 2.8rem !important;
          height: 13vw !important;
          max-width: 100% !important;
          max-height: 2.87rem !important;
        }
      }

      .text_btn_buy {
        font-size: clamp(1.3rem, 2vw, 1.5rem);
      }
    }
  }
  .btn_container {
    .btn_custom {
      @include screen_mobile {
        width: 65% !important;
        min-width: 10rem;
      }
    }
    .btn_text {
      color: var(--white-color);
      font-size: 1.75rem;
    }
  }
}
