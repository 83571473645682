@import "/src/_styles/mixin";

.ico_diamond_white {
  width: 1.2rem;
}

.package_item_tariff {
  min-height: 79rem !important;

  @include screen_mobile {
    min-height: 72rem !important;
  }
}

.pdElement {
  padding-bottom: 1.45rem !important;
  padding-top: 1.45rem !important;

  @include screen_mobile {
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
  }
}

.tariff_item_accounts {
  padding: 0.6rem 0 1.9rem !important;
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end !important;

  @include screen_mobile {
    min-height: 5rem;
  }

  .tariff_item_btn {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}

.tariff_item_accounts_special {
  min-height: 12.75rem;

  @include screen_mobile {
    min-height: 12.8rem;
  }
}

.tariff_item_accounts_normal {
  @include screen_mobile {
    padding: 0.6rem 0 1.15rem !important;
  }
}

.input_change_account {
  outline: none;
  max-width: 7.5rem;
  width: 7.5rem;
  min-width: 6rem;
  margin-right: 0.5rem;
  height: 90%;
  background: #12608e;
  border-radius: 0.5rem;

  padding: 0.1rem 0.3rem;

  @include screen_mobile {
    padding: 0.3rem;
  }
  input {
    outline: none;
    margin-right: 0.3rem;
    border-radius: 0.5rem;
    color: var(--white-color) !important;
    border: none;
    background-color: transparent;
    width: 100%;
    flex: 1;
    height: 90%;
    &:focus {
      outline: none;
    }
  }
}
