@import "/src/_styles/mixin";

.loginNew {
  position: relative;
  height: 100%;

  .content {
    padding: 0 7% 0rem;
    width: 100%;
    height: 100%;
    // min-height: 92vh;

    @include screen_mobile {
      padding: 0 0 1rem;
    }
    .content_1 {
      padding: 4rem 0;

      @include screen_mobile {
        padding: 0.5rem 0;
      }

      .form_input_login {
        // margin: 2rem 0 0;
        width: 70%;
        .input_text_base {
          @include screen_mobile {
            height: 3.3rem !important;
          }
        }

        @include screen_mobile {
          width: 96%;
        }

        .btn_auth_container {
          padding: 1rem 4% 2rem;

          @include screen_mobile {
            flex-direction: row-reverse;
          }

          .btn_custom {
            @include screen_mobile {
              max-width: 100% !important;
              max-height: 100% !important;
              height: 3rem !important;
              width: 47% !important;
            }
          }
        }

        .box_auth_social {
          width: 35%;
          height: 4.2rem;
          // max-height: 7vw;
          background-color: #f5f5f5;
          border-radius: 0.3rem;
          margin-top: 1rem;
          box-shadow: 1px 2px 4px 0 rgba(21, 27, 38, 0.4);
          min-width: 10rem;

          @include screen_mobile {
            width: 40%;
            height: 3.4rem;
            min-width: 10rem;
          }

          .icon_auth_social {
            width: 50%;
            // max-height: 5vw;
            max-height: 100%;
            padding: 0.7rem 0;

            @include screen_mobile {
              max-height: 100%;
            }
          }
        }
      }
    }
  }
}

.loginAbsolute {
  min-height: calc(100vh - 75px);

  @include screen_mobile {
    // height: calc(100vh - 75px);
  }

  .content {
    min-height: calc(100vh - 70px);
  }

  .footer_auth_container {
    // @include screen_mobile {
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   right: 0;
    //   margin-left: auto;
    //   margin-right: auto;
    //   text-align: center;
    // }
    padding-top: 20%;
  }
}
