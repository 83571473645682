@import "/src/_styles/mixin";

.list_grades {
  width: 55%;
  padding-left: 5%;

  @include screen_mobile {
    max-width: 55% !important;
  }
  .item_grade_container {
    margin: 0 3%;
    .item_grade {
      border: 1px solid#cdcdcd;
      font-size: 2.4rem;
      border-radius: 0.3rem;
      //   padding: 0 3%;
      height: 4.5vw;
      width: 4.5vw;
      min-height: 3.5rem;
      min-height: 3.5rem;
      text-align: center;

      &:hover {
        background-color: #00cc85 !important;
        color: var(--white-color) !important;
      }

      @include screen_mobile {
        font-size: 1.4rem !important;
        height: 10.8vw !important;
        width: 10.8vw !important;
        min-height: 3rem;
        min-width: 3rem;
      }
    }

    .item_grade_teacher {
      @include screen_mobile {
        font-size: 0.9rem !important;
        height: 15vw !important;
        width: 15vw !important;
      }
    }
  }
}
