@import "/src/_styles/mixin";

.registerNew {
  .content {
    .content_1 {
      padding-top: 3rem;
      @include screen_mobile {
        padding-top: 0.5rem;
      }
      .modal_container {
        width: 50% !important;
        min-width: 35rem;

        @include screen_mobile {
          min-width: 90% !important;
          max-width: 90% !important;
        }
      }

      .modal_success_register {
        @include screen_mobile {
          background-color: transparent !important;
          box-shadow: none !important;
          border: none !important ;
          // padding: 0 3%;
        }
      }
      .list_agents {
        width: 100%;
        // margin-top: 2rem;

        @include screen_mobile {
          justify-content: space-between;
        }
        .agent_item {
          padding: 0 2.5%;
          width: 25%;

          @include screen_mobile {
            width: 30%;
            padding: 0;
          }
          &:hover {
            opacity: 0.9 !important;
          }
          .tick_select {
            position: absolute;
            top: -0.2rem;
            left: 5%;

            .tick_select_img {
              max-width: 90%;
            }
          }
        }
      }

      // Modal Auth
      .modalAuth {
        min-width: 46rem;
        @include screen_tablet {
          min-width: 38rem;
        }
      }
      .form_input_login {
        width: 90% !important;
        margin-top: 2rem;
        max-width: 100%;

        @include screen_mobile {
          width: 100% !important;
        }

        .input_text_base,
        .input_date_base,
        .input_select_main_wrapper {
          @include screen_mobile {
            height: 3.3rem !important;
          }
        }

        .icon_label {
          margin-right: 20px !important;
        }
        .inputs_address_mobile {
          @include screen_mobile {
            flex-direction: column;
          }
        }
        .input_radio_base .drop_down .option_item span {
          font-family: "AvertaStdCY" !important;
        }

        .field_province {
          width: 58%;
          @include screen_mobile {
            width: 100%;
          }
          .input_select_main {
            .input_text {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .field_district {
          width: 40%;
          @include screen_mobile {
            width: 100%;
          }
          .input_select_main_wrapper {
            padding-left: 3% !important;
            padding-right: 0 !important;
            @include screen_mobile {
              padding: 0 2vw !important;
            }

            .input_text {
              width: 75% !important;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              @include screen_mobile {
                width: 100%;
                max-width: 100% !important;
              }
            }

            .icon {
              padding-right: 5% !important;
              @include screen_mobile {
                padding-right: 0 !important;
              }
            }

            .icon_label {
              display: none !important;
              @include screen_mobile {
                display: flex !important;
                justify-content: center;
              }
            }

            .cursor_pointer {
              display: flex !important;
            }
          }
        }

        .field_gender {
          position: relative;
          @include screen_mobile {
            width: 48% !important;
          }

          .icon_label {
            margin-right: 10px !important;
          }
          .input_select_main_wrapper {
            overflow: hidden;
            padding-right: 3% !important;

            @include screen_mobile {
              padding-right: 2vw !important;
            }
            .input_select_main {
              width: 100% !important;
            }
            .input_text {
              // max-width: 65% !important;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .icon {
              padding-left: 3% !important;
              @include screen_mobile {
                padding-left: 0 !important;
              }
            }
          }
        }

        .birthday_gender_container {
          margin-bottom: 0.8rem;
          .input_date_base_container {
            width: 60% !important;
            @include screen_mobile {
              width: 48% !important;
            }
          }
        }

        .grade_container {
          margin-left: 3%;
          margin-bottom: 1.5rem;
          .title_grade {
            // margin-right: 5%;
            width: 45%;

            .name_input_grade {
              margin-left: 5%;
            }
          }
        }
      }
    }
  }
}
