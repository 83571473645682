@import "/src/_styles/mixin";

.modal_form_trial {
  padding-top: 40px !important;
  padding-bottom: 40px !important;

  .header_advisement {
    .title_header_teacher_1_mobile {
      width: 35% !important;
      max-width: 35% !important;
    }

    .title_header_teacher_2_mobile {
      width: 90% !important;
      max-width: 90% !important;
    }
  }

  .form_trial {
    width: 80%;
    min-width: 50rem;
    max-width: 75rem;
    border-radius: 1.25rem;
    height: 100%;

    @include screen_mobile {
      width: 100%;
      min-width: 18rem;
      border-radius: 1.8rem;
    }

    .content_advisement_container {
      display: flex;
      border: none;
      padding: 0;
      width: 100%;
      height: 100%;
      border-radius: 1.25rem;
      min-width: 50rem;
      max-width: 75rem;
      @include screen_mobile {
        width: 100%;
        min-width: 18rem;
        border-radius: 1.8rem;
      }
      .content_advisement {
        width: 100%;
        background-color: #fddc6b;
        border-radius: 1.25rem;
        border: 2px solid var(--white-color);
        height: 100%;
        overflow-y: auto;

        @include screen_mobile {
          border-radius: 1.8rem;
        }

        .title_header_teacher {
          width: 65%;
          max-width: 38rem;
        }
        .form_input_wrapper {
          padding-top: 2.75rem;

          @include screen_mobile {
            padding-top: 1.75rem;
          }

          .input_container_left,
          .input_container_right {
            @include screen_mobile {
              width: 100%;
              max-width: 100%;
            }
          }
          .input_container_right {
            .text-address {
              position: absolute;
              top: -2rem;
              left: 1.2rem;
              font-size: clamp(0.875rem, 1.5vw, 1.1rem);

              @include screen_mobile {
                position: relative;
                top: auto;
                left: 0.75rem;
                padding-bottom: 0.5rem;
              }
            }
          }

          .input_text_base,
          .input_select_main_wrapper {
            margin-bottom: 2.1rem !important;

            @include screen_mobile {
              margin-bottom: 1.9rem !important;
            }

            .input_text {
              font-size: clamp(0.875rem, 1.6vw, 1.1rem) !important;
            }
          }

          .input_select_main_wrapper {
            padding-left: 10px;
            padding-right: 18px;
          }

          .input_radio_base {
            .drop_down {
              max-height: 295px !important;
              top: 100%;
            }
          }

          .input_select_main_wrapper {
            .input_select_main {
              .icon_label {
                margin-right: 5px;
              }
            }
          }
        }

        .input_trial_container {
          .input_text_base_container {
            width: 48%;
            max-width: 48%;
            margin: 0 auto;

            @include screen_mobile {
              width: 100%;
              max-width: 100%;
            }
            .input_text_base {
              margin-bottom: 1.25rem !important;
            }
          }

          .number_account_assistant {
            width: 48%;
            max-width: 48%;
            margin: 0 auto;
            padding: 0 1.5%;
            @include screen_mobile {
              width: 100%;
              max-width: 100%;
              padding: 0 3.5%;
            }

            .title_account_assistant {
              font-size: clamp(0.875rem, 1.5vw, 1rem);
            }
          }
        }

        // Policy Trial
        .policy-trial-container {
          background-color: var(--white-color);
          padding: 1rem calc(1.5% + 1rem);
          border-radius: 1rem;
          margin: 2rem 0;

          .policy-trial-title {
            font-size: clamp(1rem, 1.5vw, 1.125rem);
            color: var(--primary-green);
            padding: 0.25rem 0;
          }

          .policy-trial-content {
            .policy-item {
              .policy-item-title {
                padding: 0.25rem 0;

                font-size: clamp(0.875rem, 2vw, 1rem);
              }

              .policy-item-content_list {
                .policy-item-content {
                  padding: 0.25rem 0;
                  font-size: clamp(0.875rem, 2vw, 1rem);
                }
              }
            }
          }
        }
      }
    }

    .text_policy_assistant_account {
      span,
      a {
        font-size: clamp(0.75rem, 1.5vw, 0.875rem) !important;
      }
    }
  }

  .form_trial_close {
    position: absolute;
    top: -0.9rem;
    right: -0.9rem;
    cursor: pointer;

    @include screen_mobile {
      top: 0.45rem;
      right: 0.45rem;
    }

    img {
      border: 1px solid var(--white-color);
      width: 2.5rem;
      border-radius: 50%;
    }
  }

  .info-trial-success {
    background-color: var(--white-color);
    border: none;
    width: 100%;
    min-width: 50rem;
    max-width: 75rem;
    padding: 5rem 3%;
    border-radius: 1.25rem;
    align-self: center;
    max-height: 100%;
    @include screen_mobile {
      width: 100%;
      min-width: 18rem;
      border-radius: 1.8rem;
    }

    .msg-title {
      font-size: clamp(1.75rem, 2.3vw, 1.875rem);
    }
    .msg-detail {
      width: 90% !important;
      font-size: clamp(1.125rem, 1.7vw, 1.375rem);
    }

    .msg-contact {
      font-size: clamp(1.125rem, 1.7vw, 1.25rem);

      a {
        font-size: clamp(1.25rem, 2.2vw, 1.5rem);
      }
    }
  }
}
