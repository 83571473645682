.loginNew {
  .image_success {
    width: 20%;
  }
  .dot_typing {
    position: relative;
    left: -9999px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--primary-green);
    color: var(--primary-green);
    box-shadow: 9964px 0 0 0 var(--primary-green),
      9999px 0 0 0 var(--primary-green), 10034px 0 0 0 var(--primary-green);
    animation: dot-typing 1.5s infinite linear;
  }

  @keyframes dot-typing {
    0% {
      box-shadow: 9964px 0 0 0 var(--primary-green),
        9999px 0 0 0 var(--primary-green), 10034px 0 0 0 var(--primary-green);
    }
    16.667% {
      box-shadow: 9964px -10px 0 0 var(--primary-green),
        9999px 0 0 0 var(--primary-green), 10034px 0 0 0 var(--primary-green);
    }
    33.333% {
      box-shadow: 9964px 0 0 0 var(--primary-green),
        9999px 0 0 0 var(--primary-green), 10034px 0 0 0 var(--primary-green);
    }
    50% {
      box-shadow: 9964px 0 0 0 var(--primary-green),
        9999px -10px 0 0 var(--primary-green),
        10034px 0 0 0 var(--primary-green);
    }
    66.667% {
      box-shadow: 9964px 0 0 0 var(--primary-green),
        9999px 0 0 0 var(--primary-green), 10034px 0 0 0 var(--primary-green);
    }
    83.333% {
      box-shadow: 9964px 0 0 0 var(--primary-green),
        9999px 0 0 0 var(--primary-green),
        10034px -10px 0 0 var(--primary-green);
    }
    100% {
      box-shadow: 9964px 0 0 0 var(--primary-green),
        9999px 0 0 0 var(--primary-green), 10034px 0 0 0 var(--primary-green);
    }
  }
}
