.modal_container {
  border-radius: 1.3rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 1rem 1rem 1.8rem;
  min-height: 30rem;
  width: 100%;
  z-index: 99;
  background-color: var(--white-color);

  .img_delete_modal {
    width: 2.2rem;
    height: auto;
  }
}
.modal_msg {
  border-bottom: 1px solid var(--black-color);
}
.icon_sucess_msg {
  width: 5rem;
  min-width: 2.5rem;
}
