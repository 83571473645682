@import "/src/_styles/mixin";

.modal_success_register {
  padding: 2rem 0;

  .img_download_container {
    width: 80%;
    padding: 1.5rem 0 0;
    .img_qr_wrapper {
      padding: 0 4%;
    }
    .img_qr_download {
      width: 7.5rem;
      min-width: 6rem;
    }
  }

  // Mobile

  .footer_social_container {
    @include screen_mobile {
      flex-direction: row-reverse;
      justify-content: flex-end !important;
      align-items: center;
      margin: 0 !important;
      padding: 2rem 0 1rem;
    }
    .img_download_container_mobile {
      // padding: 0 1rem 0.8rem;
      @include screen_mobile {
        width: 60%;
        max-width: 60%;
        padding: 0 0 0.8rem 0;
        max-height: 90%;
      }
    }
    .footer_app_download_container {
      @include screen_mobile {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
    }

    .img_app_download {
      // height: 1.6rem;
      // min-height: 1.6rem;
      // width: 80%;
      cursor: pointer;

      @include screen_mobile {
        margin: 0 !important;
        width: 47%;
        max-height: 90%;
      }
    }
    .icon_social_container_mobile {
      @include screen_mobile {
        min-width: 38%;
        max-width: 38%;
        width: 38%;
        padding-right: 3%;
      }
      .icon_social {
        cursor: pointer;
        height: auto;
        -webkit-transition: all ease-out 0.3s;
        transition: all ease-out 0.3s;
        -webkit-transform: scale(1);
        transform: scale(1);

        @include screen_mobile {
          width: 4.5rem;
          margin: 0;
        }

        &:hover {
          -webkit-transition: all ease-out 0.3s;
          transition: all ease-out 0.3s;
          -webkit-transform: scale(1.3);
          transform: scale(1.3);
        }
      }
    }
  }

  // Button
  .btn_auth_container {
    padding-top: 1rem;
    max-width: 94%;

    @include screen_mobile {
      flex-direction: row-reverse;
      max-width: 100%;
    }

    .btn_custom {
      @include screen_mobile {
        max-height: 3rem !important;
        max-width: 44% !important;
      }
    }
  }

  .icon_sucess_msg {
    @include screen_mobile {
      width: 7rem;
      min-width: 6rem;
    }
  }

  .icon_social_container {
    width: 40%;
    padding-top: 1rem;
    @include screen_mobile {
      // min-width: 38%;
      // max-width: 38%;
      // width: 38%;
      // padding-right: 3%;
    }

    .icon_social {
      cursor: pointer;
      width: 5rem;
      height: auto;
      -webkit-transition: all ease-out 0.3s;
      transition: all ease-out 0.3s;
      -webkit-transform: scale(1);
      transform: scale(1);
      margin: 0 0.3rem;

      @include screen_mobile {
        width: 4rem;
      }

      &:hover {
        -webkit-transition: all ease-out 0.3s;
        transition: all ease-out 0.3s;
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
      }
    }
  }
}
