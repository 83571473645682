@import "/src/_styles/mixin";

.modalAuth {
  background-color: var(--white-color);
  border-radius: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 2rem 3% 3rem;
  width: 60%;
  min-width: 38rem;
  z-index: 999;

  @include screen_mobile {
    width: 94% !important;
    padding: 2rem 0 3rem;
    background-color: transparent;
    box-shadow: none;
    min-width: 94% !important;
  }

  .modal_desc_1 {
    padding-right: 3%;
  }

  .title_modal_auth {
    @include screen_mobile {
      text-transform: initial !important;
    }
  }
}
